
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Prop} from 'vue-property-decorator';

    import {formHelper} from '@/helpers';
    import {
        IOrganization,
        IUser,
    } from '@/types';

    import {SnackbarModule} from '@/store/modules';

    import PhoneInput from '@/components/forms/PhoneInput.vue';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

    @Component({
        components: {
            PhoneInput,
        },
    })
    export default class ContactForm extends Vue {
        public contactFormValid: boolean = false;
        public loading: boolean = false;

        public snackbar: boolean = false;
        public snackbarColor: string = '';
        public snackbarText: string = '';

        // Prospect form
        public organizationName: string|null = null;
        public lastName: string|null = null;
        public phone: string|null = null;
        public postalCode: string|null = null;

        // Classic form
        public name: string|null = null;
        public email: string|null = null;
        public subject: string|null = null;
        public message: string|null = null;

        public emailRules = formHelper.getEmailsRules();
        public nameRules = formHelper.getNameRules();
        public contactSubjectRules = formHelper.getContactSubjectRules();
        public contactMessageRules = formHelper.getContactMessageRules();

        public organizationNameRules = formHelper.getOrganizationNameRules();

        @accountNamespace.Getter('loggedUser')
        public user!: IUser|null;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @Prop({
            type: Boolean,
            required: false,
            default: true,
        })
        public showTitle!: boolean;

        public get reasons() {
            if (this.user) {
                return [
                    {value: 'technical', text: 'Je signale un dysfonctionnement'},
                    {value: 'help', text: 'Je souhaite être accompagné pour configurer Vetolib'},
                    {value: 'other', text: 'Je souhaite poser une autre question'},
                ];
            }

            return [
                {value: 'prospect', text: 'Je suis Vétérinaire et souhaite en savoir plus sur Vetolib'},
                {value: 'client', text: 'Je souhaite prendre rendez-vous en ligne avec mon Vétérinaire'},
                {value: 'technical', text: 'Je signale un dysfonctionnement'},
                {value: 'other', text: 'Je souhaite poser une autre question'},
            ];
        }

        public submitProspect() {
            this.loading = true;
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.$api.contact
                .sendMessage({
                    description: this.message,
                    last_name: this.lastName,
                    organization_name: this.organizationName,
                    phone: this.phone ? this.phone : '',
                    postal_code: this.postalCode,
                    email: this.email as string,
                    subject: 'prospect',
                    type: 'contact',
                })
                .then(() => {
                    this.resetForm();
                    snackbarModule.displaySuccess(
                        `Votre message a bien été envoyé.
                        Nous reviendrons vers vous dans les plus brefs délais !`,
                    );
                })
                .catch(() => {
                    snackbarModule.displayError(
                        `Une erreur est survenue pendant l'envoi de votre message.
                        Veuillez réessayer ultérieurement.`,
                    );
                })
                .finally(() => this.loading = false)
            ;
        }

        public submitContact() {
            this.loading = true;
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this
                .$recaptcha('contact')
                .then((token: string) => {
                    this.$api.contact
                        .sendMessage({
                            account: this.user ? this.user.account.id : null,
                            email: this.email as string,
                            message: this.message as string,
                            name: this.name as string,
                            organization: this.loggedOrganization ? {
                                id: this.loggedOrganization.id,
                                name: this.loggedOrganization.name,
                            } : null,
                            subject: this.subject as string,
                            tags: ['pro'],
                            token,
                            type: 'contact',
                        })
                        .then(() => {
                            this.resetForm();
                            snackbarModule.displaySuccess(
                                `Votre message a bien été envoyé.
                                Nous reviendrons vers vous dans les plus brefs délais !`,
                            );
                        })
                        .catch(() => {
                            snackbarModule.displayError(
                                `Une erreur est survenue pendant l'envoi de votre message.
                                Veuillez réessayer ultérieurement.`,
                            );
                        })
                        .finally(() => this.loading = false)
                    ;
                })
            ;
        }

        private resetForm() {
            this.message = null;
            this.subject = null;

            (this.$refs.contactForm as HTMLFormElement).resetValidation();
        }

        private initForm() {
            if (!this.user) {
                return;
            }

            if (this.user.veterinarian) {
                this.name = `${this.user.veterinarian.first_name} ${this.user.veterinarian.last_name}`;
            }

            if (this.user.paraveterinarian) {
                this.name = `${this.user.paraveterinarian.first_name} ${this.user.paraveterinarian.last_name}`;
            }

            if (this.user.account) {
                this.email = this.user.account.email;
            }
        }

        private mounted() {
            this.initForm();
        }
    }
