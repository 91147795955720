
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import ContactForm from '@/components/ContactForm.vue';

    @Component<ViewFaq>({
        components: {
            ContactForm,
        },
    })
    export default class ViewFaq extends Vue {
        private panels = [8];
        private sections = [
            {
                title: 'Configuration du compte (profil Vétérinaire et établissement)',
                content: `
                    Vous devez impérativement compléter votre profil afin qu’il soit visible et bien référencé.
                    Veillez à ajouter une présentation pour chaque vétérinaire (présentation, photos et diplômes).
                    <br>
                    <br>
                    Le profil de l'établissement est capital et sera directement visible dans les résultats de recherche de Vetolib.
                    Veillez à ajouter les informations pratiques (présentation, photos, honoraires et horaires de secrétariat), une éventuelle vidéo de présentation (Youtube), un lien vers votre boutique en ligne et celui de votre page Facebook pour qu’elle s’affiche sur votre profil Vetolib afin de gagner en visibilité sur internet.
                `,
                youtubeLink: 'https://youtu.be/l4hhPn5tpPg',
            },
            {
                title: 'Motifs de consultation',
                content: `
                    Vous avez la possibilité de créer, modifier la durée ou supprimer vos motifs de consultation dans les paramètres "Motifs de consultation".
                    <br>
                    <br>
                    Les motifs de consultation normés apparaissent dans le filtre de recherche Vetolib pour les clients et permettent d’en filtrer les résultats. Nous vous recommandons d’utiliser les motifs normés autant que possible avant de créer des motifs de consultation personnalisés.
                    <br>
                    <br>
                    Nous vous déconseillons de créer des motifs personnalisés tels que Consultation Chat ou Consultation Chien si la durée ne change pas. Préférez toujours le motif normé. L’espèce de l’animal est toujours communiqué lors de la prise de RDV en ligne donc il n’est pas nécessaire de créer des motifs spécifiques pour chaque espèce.
                `,
                youtubeLink: 'https://youtu.be/_hvhcgMfl4U',
            },
            {
                title: 'Création et paramétrage des agendas',
                content: `
                    Lors de la mise en place de Vetolib, vous devez créer votre agenda pour ajouter vos disponibilités et activer la prise de rendez-vous en ligne.
                    <br>
                    <br>
                    Vous avez la possibilité de créer ou de supprimer un agenda, d'y associer une couleur, de l'ajouter dans un groupe et de le rendre visible en ligne ou non (utile pour préparer l'arrivée d'un nouveau praticien).
                    <br>
                    <br>
                    Vous devez créer un agenda pour chaque praticien. Vous pourrez ensuite activer ou non la réservation en ligne pour chacun d’entre eux.
                `,
                youtubeLink: 'https://youtu.be/IBALnQ9aya8',
            },
            {
                title: 'Affichage de l\'agenda',
                content: `
                    Il est possible de personnaliser rapidement l'affichage de votre agenda en fonction de vos besoins afin de visualiser de manière optimale les agendas et rendez-vous sur votre écran.
                    <br>
                    Vous pouvez masquez les jours qui ne vous intéressent pas et ajuster la tranche horaire selon les heures de travail.
                    <br>
                    <br>
                    L’ergonomie de l’agenda a été pensée de manière à ce que vous puissiez naviguer simplement de semaine en semaine et de jour en jour sans jamais perdre le fil.
                `,
                youtubeLink: 'https://youtu.be/2qzSCdFuI54',
            },
            {
                title: 'Horaires d’ouvertures et Réservation en ligne',
                content: `
                    La création d'une plage d'ouverture associée à des motifs de consultation permet à vos clients de prendre rendez-vous en ligne aux heures, aux jours et aux motifs de votre choix. Chaque plage est entièrement personnalisable et vous pouvez proposer des motifs spécifiques à des périodes spécifique pour la réservation en ligne.
                    <br>
                    <br>
                    Exemple : Vous pouvez proposer à vos clients de prendre rendez-vous en ligne pour le motif "Vaccination" uniquement le lundi matin de 10h à 12h et le motif "Identification" toute la semaine en début d'après-midi de 13h à 15h.
                    <br>
                    <br>
                    Veillez toujours à ajouter toutes les plages d’ouverture correspondant à vos horaires de travail même celles qui ne sont éventuellement pas proposées à la réservation en ligne.
                `,
                youtubeLink: 'https://youtu.be/ENfO-pVD9vc',
            },
            {
                title: 'Absences (indisponibilités)',
                content: `
                    L'ajout d'une indisponibilité permet à la fois de créer et de préciser sur l'agenda une période d'absence exceptionnelle ou un évènement particulier. La prise de rendez-vous en ligne est alors désactivée durant cette période.
                    <br>
                    <br>
                    Vous pouvez répétez des indisponibilités et ajouter un titre et une description visibles directement sur l’agenda.
                `,
                youtubeLink: 'https://youtu.be/Kz5eRzJV9iw',
            },
            {
                title: 'Gestion des rendez-vous',
                content: `
                    Vetolib a été conçu afin d'optimiser la gestion quotidienne de votre activité et de vos RDV grâce à une ergonomie et à des outils intelligents.
                    <br>
                    <br>
                    Votre agenda sur mesure et entièrement paramétrable en quelques clics vous permet d'adapter l’affichage en fonction de vos besoins immédiats. La messagerie intégrée à chaque RDV facilite considérablement la communication et l’échange d’information avec vos clients.
                    <br>
                    <br>
                    Les fonctionnalités relatives à la gestion des RDV permettent d'accéder très rapidement à toutes les informations nécessaires à la prise et au suivi des rendez-vous de manière intuitive.
                    <br>
                    <br>
                    Les statuts de présence vous permettent de visualiser l'état des RDV directement depuis l'agenda et facilite l’organisation.
                `,
                youtubeLink: 'https://youtu.be/rbxMesnnYSY',
            },
            {
                title: 'Messagerie instantanée et Notifications',
                content: `
                    La messagerie instantanée intégrée à tous vos RDV vous permet de communiquer efficacement et rapidement avec tous vos clients. Vous pouvez demander des informations, partager des documents directement depuis votre agenda : Vos clients seront notifiés et pourront vous répondre instantanément depuis leur smartphone.
                    <br>
                    <br>
                    Les notifications permettent de savoir en temps réel si un nouveau rendez-vous a été pris en ligne, si un rendez-vous a été modifié (annulation et déplacement) et si un nouveau message ou une pièce jointe a été ajouté(e) sur le RDV par le client.
                    <br>
                    <br>
                    Si les notifications ont été activées sur votre navigateur internet (Mozilla Firefox, Google Chrome, Microsoft Edge), vous recevrez des notifications sur le bureau même si vous ne visualisez pas l’agenda afin d’être toujours notifié.
                `,
                youtubeLink: 'https://youtu.be/qV2u0lxALME',
             },
        ];

        public getVideoId(youtubeURL: string) {
            const regExp: RegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            const match = youtubeURL.match(regExp);
            return (match && match[7].length === 11) ? match[7] : false;
        }
    }
